var form = $('#js-enrollment-form');
var current_section = 1;
var current_section_object = $(form.find('.js-form-step:visible:first'));

$('.js-reveal-enrollment-form').on('click', function () {

    $('.js-pre-enrollment').hide();
    $('.enrollment-form-marketing-copy').hide();

  // Hide Navigation and remove clickable logo

    $('.js-primary-navigation').addClass('visibly-hidden');
    $('.js-mobile-menu-toggle').hide();
    $('.site-logo-clickable').hide();
    $('.site-logo-unclickable').show();

    $('.js-enrollment-form-wrapper').slideDown('slow', function () {
        console.log('Revealed Enrollment Form');
        $('.form-field-wrapper').first().find('input')[0].focus({ preventScroll: true });
    });

});

/* Validates each section of the enrollment form and submits after all sections have been validated */

$('.js-validate-and-submit').on('click', function (event) {

    event.preventDefault();

  // Resets
    $('.form-field-wrapper').removeClass('missing');
    $('.js-error-contents').html('');
    $('.js-form-alert-error-response').hide();
    $('.missing-section').removeClass('missing-section');

  // Aria Resets
    $('input').removeAttr('aria-invalid');
    $('select').removeAttr('aria-invalid');
    $('textarea').removeAttr('aria-invalid');

  // Variables

    let number_of_sections = $('.js-form-step').length;

    console.log('Number of Sections: ' + number_of_sections);
    console.log('Current Section: ' + current_section);


    if (number_of_sections == 1) {
        form.submit();
    } else {
      // We have a multi part form so we need to validate each section as the user interacts with it and also enable the appropriate toggle
      // Figure out which section of the form we are on

        current_section = form.find('.js-form-step:visible:first').data('step');
        current_section = parseInt(current_section);

        console.log('Current Section: ' + current_section);

        current_section_object = $(form.find('.js-form-step:visible:first'));

      /*
      console.log('Current Section Object:');
      console.log(current_section_object.html());
      */

      // Need to go through each set of required fields for this section and see if user filled them all out

        let required = current_section_object.find('input,textarea,select').filter('[required]:visible');
        let error_message = '';

        let allRequired = true;
        required.each(function () {
            if ($(this).val() == '') {
                allRequired = false;

                let parent_wrapper = $(this).closest('.form-field-wrapper');
                let label = $(parent_wrapper).first().find('label');

                $(parent_wrapper).addClass('missing');

                let error_string = $('.js-error-message-field-required').first().html();
                error_string = error_string.replace(':FIELD:', $(label).html());

                error_message = error_message + '<li>' + error_string + '</li>';

              // Mark the field as invalid for screen readers
                $(this).attr('aria-invalid', true);
            }
        });

      // Some additional validation for a couple of special areas
      // Destination checkboxes

        if (form.find('.js-combo-enrollment-fields').first().is(':visible')) {
            console.log('The destination fields are active');

          // Make sure at least one destination has been selected
            if (form.find('.js-combo-enrollment-fields input:checked').length <= 0) {
                console.log('User did not select any destinations');
                error_message = error_message + '<li>' + $('.js-error-message-dest-required').first().html() + '</li>';
                $('.destinations-label').addClass('missing-section');
            }
        }

        if (form.data('site') == 'rci-discover' && current_section == 2) {
            console.log('Attempt to validate Discover Step 2');

          // Phone type for primary phone

            if (!$('#js-Home-primary_phone_type').is(':checked') && !$('#js-Mobile-primary_phone_type').is(':checked')) {
                error_message = error_message + '<li>' + $('.js-error-message-phone-type-required').first().html() + '</li>';
                $('.phone-primary').find('.phone-type').find('legend').addClass('missing-section');
                allRequired = false;
            }
        }

        if (form.data('site') == 'rci-discover' && current_section == 3) {
            console.log('Attempt to validate Discover Step 3');

          // RCI Membership Ask
            if (!$('#js-yes-previous_member').is(':checked') && !$('#js-no-previous_member').is(':checked')) {
                error_message = error_message + '<li>' + $('.js-error-message-prev-member-required').first().html() + '</li>';
                $('.previous-member').find('legend').addClass('missing-section');
                allRequired = false;
            }

          // Users must agree to program terms of service
            if (!$('#js-agree-tos').is(':checked')) {
                error_message = error_message + '<li>' + $('.js-error-message-terms-agree-required').first().html() + '</li>';
                $('.agree-tos-container').find('label').addClass('missing-section');
                allRequired = false;
            }
        }

      // Family Share Validation

        if (form.data('site') == 'rci-family-share' && current_section == 1) {
            console.log('Attempt to validate Family Share Step 1');

          // Phone type for primary phone

            if (!$('#js-Home-primary_phone_type').is(':checked') && !$('#js-Mobile-primary_phone_type').is(':checked')) {
                error_message = error_message + '<li>Phone type is required for your primary phone number</li>';
                $('.phone-primary').find('.phone-type').find('legend').addClass('missing-section');
                allRequired = false;
            }
        }

        if (form.data('site') == 'rci-family-share' && current_section == 2) {
          // Users must agree to program terms of service
            if (!$('#js-agree-tos').is(':checked')) {
                error_message = error_message + '<li>You must agree to the program enrollment terms in order to proceed</li>';
                $('.agree-tos-container').find('label').addClass('missing-section');
                allRequired = false;
            }
        }

        if (allRequired === true) {
          // Hide current section and show next section

            if (current_section + 1 > number_of_sections) {
                form.submit();
            } else {
                current_section_object.hide();
                current_section++;
                current_section_object = form.find('.js-form-step[data-step="' + current_section + '"]');
                current_section_object.show().focus();

              // Update the steps widgets display
                $('.js-form-tab').attr('aria-selected', 'false');
                $('.js-enrollment-progress-steps').find('.tab[data-step="' + current_section + '"]').removeAttr('disabled').addClass('completed').attr('aria-selected', true);

              // Scroll to the new area we are editing
                $('html, body').animate({scrollTop: current_section_object.offset().top - 150}, 'slow');
            }
        } else {
            error_message = '<ul>' + error_message + '</ul>';

            $('.js-error-contents').html($('.js-error-message-correct-errors').first().html() + error_message);
            $('.js-form-alert-error-response').show();

            $('html, body').animate({scrollTop: $('.js-form-alert-error-response').first().offset().top - 150}, 'slow');

            current_section_object.focus();
        }
    }

});

$('.js-form-tab').on('click', function () {

    var button = $(this);
    $('.js-form-tab').attr('aria-selected', 'false');
    button.attr('aria-selected', true);

    $('.js-form-step').hide();
    current_section = button.data('step');
    current_section_object = form.find('.js-form-step[data-step="' + current_section + '"]');
    current_section_object.show().focus();

});
