/* eslint-disable */

var scrollOffset = 150;

/* Check for mobile */
if( $("#js-mobile-detector").css('opacity') == 1) {
  scrollOffset = 100;
}

$(document).on('click', 'a[href^="/#"]', function (event) {

    if ($('.site-header').first().hasClass('is-home') && $.attr(this, 'href') != "#") {
        event.preventDefault();

        var clickedElement = $.attr(this, 'href').replace('/', '');

        if ( $("#js-mobile-detector").css('opacity') == 1) {
          // On mobile, close the menu if it is open

            if ($('.js-mobile-menu-toggle').hasClass('open')) {
                $('.js-mobile-menu-toggle').trigger('click');
            }
        }
        // scroll to the clicked element
        $('html, body').animate({
            scrollTop: $(clickedElement).offset().top - scrollOffset
        }, 500, function () {});
    }
});

$(window).on("load", function () {

    if (window.location.hash) {
        var hash = window.location.hash;

        if ($(hash).length) {
            $('html, body').animate({
                scrollTop: $(hash).offset().top - scrollOffset
            }, 500, function () {
                $(hash).focus();
            });
        }
    }

});

/* eslint-enable */
